import { createSlice } from '@reduxjs/toolkit';
import {
  coursesClearFilter,
  setFilterCourse,
  clearFiltersChains,
  valueLoopId,
  onRuleValue,
  onChangeRule,
  onChangeTime,
  onChangeLoopSpeed,
  onChangeRegulation,
  onChangeTimeProfit,
  onChangeTimeProfitWeek,
  onChangeTimeProfitMonth,
  onChangeTimeProfitPercent,
  onChangeTimeProfitPercentWeek,
  onChangeTimeProfitPercentMonth,
  onChangeRuleWeek,
  onChangeRuleMonth,
  valueOrder,
  loopWithTimeInterval,
  loopBadStatus,
  setIsModeFilter,
  setActiveFieldFilters,
  resetIndispensableFilters,
  setModeChainsStatus,
  onSelectCourse,
  onSelectAllCourse,
  onSelectPlatform,
  onSelectAllPlatform,
  onSelectCurrency,
  onSelectAllCurrency,
  setCurrentProfitability,
  setAverageIncomeDuringProfitability,
  setAverageIncomeDuringProfitabilityWeek,
  setAverageIncomeDuringProfitabilityMonth,
  onSetSelectedMethods,
  onDeleteSelectedMethods,
} from './actions';
import * as asyncActs from './async';
import { chainsFiltersInitialState, coursesFiltersInitialState } from './constants';
import {
  ActiveFilterChains,
  CoursesActiveFilters,
  DataChainsFull,
  DataMetric,
  IDataChainsTime,
} from './types';

export interface FiltersState {
  loadingState: 'loading' | 'failed' | 'success';
  requestCompiledFilters: any;
  courseFiltersChains: any;
  courseFilters?: any;
  error?: string;

  coursesActiveFilters: CoursesActiveFilters,

  loopId: string,
  selectedPlatforms: DataMetric[],
  selectedCourses: DataMetric[],
  selectedCurrency: DataMetric[],
  selectedMethods: DataMetric[],
  ruleValue: number,
  valueRangeRegulation: number[],
  valueRangeRule: number[],
  valueRangeRuleWeek: number[],
  valueRangeRuleMonth: number[],
  valueRangeTimeProfit: number[],
  valueRangeTimeProfitWeek: number[],
  valueRangeTimeProfitMonth: number[],
  valueRangeTimeProfitPercent: number[],
  valueRangeTimeProfitPercentWeek: number[],
  valueRangeTimeProfitPercentMonth: number[],
  valueRangeTime: number[],
  valueRangeLoopSpeed: number[],
  order: [string, boolean] | string,
  isModeFilter: boolean;
  chains: any,
  loopChains: DataChainsFull[];
  dataDay: IDataChainsTime[];
  dataWeek: IDataChainsTime[];
  dataMonth: IDataChainsTime[];
  totalPages: number;
  statusChains?: number;
  modeChainsStatus: number[];
  currentProfitability: number[];
  averageIncomeDuringProfitability: number[],
  averageIncomeDuringProfitabilityWeek: number[],
  averageIncomeDuringProfitabilityMonth: number[],
  activeFiltersChains: ActiveFilterChains;
  loopsCount: number;
  loopsСountSubscribe: number;
}

const initialState: FiltersState = {
  loadingState: 'success',
  requestCompiledFilters: {},
  courseFilters: {},
  courseFiltersChains: {},

  coursesActiveFilters: coursesFiltersInitialState,

  loopId: '',
  selectedPlatforms: [],
  selectedCourses: [],
  selectedCurrency: [],
  selectedMethods: [],
  ruleValue: 10,
  valueRangeRegulation: [2, 10],
  valueRangeRule: [-30, 30],
  valueRangeRuleWeek: [-30, 30],
  valueRangeRuleMonth: [-30, 30],
  valueRangeTimeProfit: [0, 120],
  valueRangeTimeProfitWeek: [0, 120],
  valueRangeTimeProfitMonth: [0, 120],
  valueRangeTimeProfitPercent: [0, 100],
  valueRangeTimeProfitPercentWeek: [0, 100],
  valueRangeTimeProfitPercentMonth: [0, 100],
  valueRangeTime: [0, 7],
  valueRangeLoopSpeed: [0, 4],
  order: ['', false],
  isModeFilter: true,
  modeChainsStatus: [1, 2, 3],
  currentProfitability: [-10, 30],
  averageIncomeDuringProfitability: [-10, 30],
  averageIncomeDuringProfitabilityWeek: [-10, 30],
  averageIncomeDuringProfitabilityMonth: [-10, 30],
  activeFiltersChains: chainsFiltersInitialState,
  chains: {},
  loopChains: [],
  dataDay: [],
  dataWeek: [],
  dataMonth: [],
  totalPages: 1,
  loopsCount: 0,
  loopsСountSubscribe: 0,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    coursesClearFilter,
    setFilterCourse,
    clearFiltersChains,
    valueLoopId,
    onRuleValue,
    onChangeRule,
    onChangeTime,
    onChangeLoopSpeed,
    onChangeRegulation,
    onChangeTimeProfit,
    onChangeTimeProfitWeek,
    onChangeTimeProfitMonth,
    onChangeTimeProfitPercent,
    onChangeTimeProfitPercentWeek,
    onChangeTimeProfitPercentMonth,
    onChangeRuleWeek,
    onChangeRuleMonth,
    valueOrder,
    loopWithTimeInterval,
    loopBadStatus,
    setIsModeFilter,
    setActiveFieldFilters,
    resetIndispensableFilters,
    setModeChainsStatus,
    onSelectCourse,
    onSelectAllCourse,
    onSelectPlatform,
    onSelectAllPlatform,
    onSelectCurrency,
    onSelectAllCurrency,
    setCurrentProfitability,
    setAverageIncomeDuringProfitability,
    setAverageIncomeDuringProfitabilityWeek,
    setAverageIncomeDuringProfitabilityMonth,
    onSetSelectedMethods,
    onDeleteSelectedMethods,
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActs.getClearFilters.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getClearFilters.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.getClearFilters.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.requestCompiledFilters = payload;
      });

    builder
      .addCase(asyncActs.getClearLoopFilters.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.getClearLoopFilters.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.getClearLoopFilters.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.requestCompiledFilters = payload;
      });

    builder
      .addCase(asyncActs.postChainsAndFilters.pending, (state) => {
        state.loadingState = 'loading';
      })
      .addCase(asyncActs.postChainsAndFilters.rejected, (state, { error }) => {
        state.loadingState = 'failed';
        state.error = error.code;
      })
      .addCase(asyncActs.postChainsAndFilters.fulfilled, (state, { payload }) => {
        state.loadingState = 'success';
        state.chains = payload;

        state.statusChains = payload?.status;
        state.loopChains = payload.data?.loops;
        state.dataDay = payload.data?.day;
        state.dataWeek = payload.data?.week;
        state.dataMonth = payload.data?.month;
        state.totalPages = payload.data?.total_pages;
        state.loopsCount = payload.data?.loops_count;
        state.loopsСountSubscribe = payload.data?.loops_count_subscribe;
      });
  },
});

// экспортишь все в actions
export const {
  coursesClearFilter: coursesClearFilterAction,
  setFilterCourse: setFilterCourseAction,
  clearFiltersChains: clearFiltersChainsAction,
  valueLoopId: valueLoopIdAction,
  onChangeRule: onChangeRuleAction,
  onChangeTime: onChangeTimeAction,
  onChangeLoopSpeed: onChangeLoopSpeedAction,
  onChangeRegulation: onChangeRegulationAction,
  onChangeTimeProfit: onChangeTimeProfitAction,
  onChangeTimeProfitWeek: onChangeTimeProfitWeekAction,
  onChangeTimeProfitMonth: onChangeTimeProfitMonthAction,
  onChangeTimeProfitPercent: onChangeTimeProfitPercentAction,
  onChangeTimeProfitPercentWeek: onChangeTimeProfitPercentWeekAction,
  onChangeTimeProfitPercentMonth: onChangeTimeProfitPercentMonthAction,
  onChangeRuleWeek: onChangeRuleWeekAction,
  onChangeRuleMonth: onChangeRuleMonthAction,
  valueOrder: valueOrderAct,
  loopWithTimeInterval: loopWithTimeIntervalAct,
  loopBadStatus: loopBadStatusAct,
  setIsModeFilter: setIsModeFilterAct,
  setActiveFieldFilters: setActiveFieldFiltersActions,
  resetIndispensableFilters: resetIndispensableFiltersAction,
  setModeChainsStatus: setModeChainsStatusAction,
  onSelectCourse: onSelectCourseAction,
  onSelectAllCourse: onSelectAllCourseAction,
  onSelectPlatform: onSelectPlatformAction,
  onSelectAllPlatform: onSelectAllPlatformAction,
  onSelectCurrency: onSelectCurrencyAction,
  onSelectAllCurrency: onSelectAllCurrencyAction,
  setCurrentProfitability: setCurrentProfitabilityAction,
  setAverageIncomeDuringProfitability: setAverageIncomeDuringProfitabilityAction,
  setAverageIncomeDuringProfitabilityWeek: setAverageIncomeDuringProfitabilityWeekAction,
  setAverageIncomeDuringProfitabilityMonth: setAverageIncomeDuringProfitabilityMonthAction,
  onSetSelectedMethods: onSetSelectedMethodsAction,
  onDeleteSelectedMethods: onDeleteSelectedMethodsAction,
} = filtersSlice.actions;

export default filtersSlice.reducer;
