import React, {
  memo,
  useEffect,
  useState,
} from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import * as Styled from './Admin.styled';
import {
  ActiveElement,
  DiscoveryMenu,
} from './Admin.types';
import { AddingChains } from './components/AddingChains';
import { DeleteChains } from './components/DeleteChains';
import { DeleteLoopsAuto } from './components/DeleteLoopsAuto';
import { DeleteLoopsByCsv } from './components/DeleteLoopsByCsv';
import { DownloadCsv } from './components/DownloadCsv';
import { PlatformGraph } from './components/PlatformGraph';
import { SampleTable } from './components/SampleTable';
import { SearchChains } from './components/SearchChains';
import { StoppingProcesses } from './components/StoppingProcesses';
import { UpdateMetrics } from './components/UpdateMertics';
import {
  adminTableMetaData,
  adminTableMetaDataCountry,
  metaDataTablePlatform,
} from './constants';
import { MetaTags } from '../../components/MetaTags';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import {
  getAnalisisiGrachLog, getEmail,
} from '../../redux/reducers/algorithms/async';
import {
  getStateDeleteLoops,
  getStateDeleteLoopsAuto,
  getUpdateMetricsStatus,
} from '../../redux/reducers/createData/async';
import {
  setActiveElementAdminPanelAct,
  setOpenMenuAction,
} from '../../redux/reducers/navigation';
import {
  getCountries,
  getMetrics,
  getCurrencies,
  getMethodsCurrent,
  getPlatformsCurrent,
  getPlatformGraph,
  getAllMetrics,
} from '../../redux/reducers/tabels/async';

function Admin() {
  const dispatch = useAppDispatch();
  const {
    methodCurrent,
    platformsCurrent,
    countries,
    currencies,
    metricPlatform,
    metricMethod,
    metricCurrency,
  } = useAppSelector((state) => state.tabels);
  const {
    activeElementAdminPanel,
    openMenuItems,
    choisePageDirectories,
  } = useAppSelector((state) => state.navigation);
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [bodyTable, setBodyTable] = useState<any>([]);
  const [launchMetrics, setLaunchMetrics] = useState(false);

  const renderViewContent = () => {
    switch (activeElementAdminPanel) {
      case ActiveElement.SearchChains:
        return <SearchChains/>;
      case ActiveElement.Adding:
        return <AddingChains/>;
      case ActiveElement.CVS:
        return <DownloadCsv/>;
      case ActiveElement.DeleteChain:
        return <DeleteChains/>;
      case ActiveElement.UpdateMetrics:
        return <UpdateMetrics/>;
      case ActiveElement.StoppingProcesses:
        return <StoppingProcesses/>;
      case ActiveElement.PlatformGraph:
        return <PlatformGraph/>;
      case ActiveElement.DeleteLoopsByCsv:
        return <DeleteLoopsByCsv/>;
      case ActiveElement.DeleteLoopsAuto:
        return <DeleteLoopsAuto/>;
      case ActiveElement.Countries:
        return (
          <SampleTable
            body={bodyTable}
            totalPages={totalPages}
            dataMetaHeaders={adminTableMetaDataCountry}
          />
        );
      case ActiveElement.Platforms:
        return (
          <SampleTable
            body={platformsCurrent.table || []}
            totalPages={platformsCurrent.total_pages}
            dataMetaHeaders={metaDataTablePlatform}
            isNavigatePlatform
          />
        );
      default:
        return (
          <SampleTable
            body={bodyTable}
            totalPages={totalPages}
            dataMetaHeaders={adminTableMetaData}
          />
        );
    }
  };

  function onClickOpenMenuHandler(menu: DiscoveryMenu) {
    dispatch(setOpenMenuAction(menu));
  }

  function onClickActiveElementMenu(element: ActiveElement) {
    dispatch((setActiveElementAdminPanelAct(element)));
  }

  // Запрашиваем метрики и email пользователя, если не получали до этого момента
  function setIsMetricDataLoaded() {
    if (!metricPlatform.length || !metricMethod.length || !metricCurrency.length) {
      dispatch(getAllMetrics({ authKey }));
      dispatch(getEmail({ authKey }));
    }
  }

  function getMetricsAndEmail() {
    if (!launchMetrics) {
      dispatch(getMetrics({ authKey }));
      dispatch(getEmail({ authKey }));
      setLaunchMetrics(true);
    }
  }

  const chooseAnActions = (data: string) => {
    switch (data) {
      case ActiveElement.Method:
        dispatch(getMethodsCurrent(
          {
            authKey,
            numberPage: choisePageDirectories.get(activeElementAdminPanel) || 1,
          },
        ));
        break;
      case ActiveElement.Platforms:
        dispatch(getPlatformsCurrent(
          {
            authKey,
            numberPage: choisePageDirectories.get(activeElementAdminPanel) || 1,
          },
        ));
        break;
      case ActiveElement.Countries:
        dispatch(getCountries(
          {
            authKey,
            numberPage: choisePageDirectories.get(activeElementAdminPanel) || 1,
          },
        ));
        break;
      case ActiveElement.Currencies:
        dispatch(getCurrencies(
          {
            authKey,
            numberPage: choisePageDirectories.get(activeElementAdminPanel) || 1,
          },
        ));
        break;
      case ActiveElement.SearchChains:
        dispatch(getAnalisisiGrachLog({ authKey }));
        setIsMetricDataLoaded();
        break;
      case ActiveElement.Adding:
        getMetricsAndEmail();
        break;
      case ActiveElement.DeleteChain:
        dispatch(getStateDeleteLoops({ authKey }));
        getMetricsAndEmail();
        break;
      case ActiveElement.UpdateMetrics:
        dispatch(getUpdateMetricsStatus({ authKey }));
        break;
      case ActiveElement.DeleteLoopsByCsv:
        dispatch(getStateDeleteLoops({ authKey }));
        break;
      case ActiveElement.DeleteLoopsAuto:
        dispatch(getStateDeleteLoops({ authKey }));
        dispatch(getStateDeleteLoopsAuto({
          authKey,
          isNeedToChange: false,
        }));
        break;
      case ActiveElement.PlatformGraph:
        dispatch(getPlatformGraph({ authKey }));
        setIsMetricDataLoaded();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    chooseAnActions(activeElementAdminPanel);
  }, [activeElementAdminPanel, choisePageDirectories]);

  useEffect(() => {
    if (Object.keys(methodCurrent).length !== 0) {
      setTotalPages(methodCurrent.data.total_pages);
      setBodyTable(methodCurrent.data.methods);
    }
  }, [methodCurrent]);

  useEffect(() => {
    if (Object.keys(countries).length !== 0) {
      setTotalPages(countries.data.total_pages);
      setBodyTable(countries.data.countries);
    }
  }, [countries]);

  useEffect(() => {
    if (Object.keys(currencies).length !== 0) {
      setTotalPages(currencies.data.total_pages);
      setBodyTable(currencies.data.currencies);
    }
  }, [currencies]);

  return (
    <Styled.AdminPanel>
      <MetaTags
        title="Arbitoring - Admin panel"
        description="Arbitoring - the best currency arbitrage in the world"
        name="Arbitoring"
        type="article"
      />
      <List
        sx={{
          width: '20%',
          height: 'calc(100% + 14px)',
          maxWidth: 300,
          minWidth: 250,
          bgcolor: '#1B1C22',
          paddingBottom: '0',
          color: '#EAE7DC',
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => onClickOpenMenuHandler(DiscoveryMenu.Directory)}>
          <ListItemText primary="Справочники"/>
          {openMenuItems.get(DiscoveryMenu.Directory) ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openMenuItems.get(DiscoveryMenu.Directory)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.Method}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.Method)}
            >
              <ListItemText primary="Методы перевода"/>
            </ListItemButton>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.Currencies}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.Currencies)}
            >
              <ListItemText primary="Валюты"/>
            </ListItemButton>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.Platforms}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.Platforms)}
            >
              <ListItemText primary="Платформы"/>
            </ListItemButton>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.Countries}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.Countries)}
            >
              <ListItemText primary="Страны"/>
            </ListItemButton>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.PlatformGraph}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.PlatformGraph)}
            >
              <ListItemText primary="Платформы граф"/>
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={() => onClickOpenMenuHandler(DiscoveryMenu.Editing)}>
          <ListItemText primary="Создать / Изменить цепочку"/>
          {openMenuItems.get(DiscoveryMenu.Editing) ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openMenuItems.get(DiscoveryMenu.Editing)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.Adding}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.Adding)}
            >
              <ListItemText primary="Добавление цепочки"/>
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={() => onClickOpenMenuHandler(DiscoveryMenu.Scripts)}>
          <ListItemText primary="Цепочки"/>
          {openMenuItems.get(DiscoveryMenu.Scripts) ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openMenuItems.get(DiscoveryMenu.Scripts)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.SearchChains}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.SearchChains)}
            >
              <ListItemText primary="Поиск цепочек"/>
            </ListItemButton>

            <ListItemButton onClick={() => onClickOpenMenuHandler(DiscoveryMenu.DeleteLoops)}>
              <ListItemText primary="Удаление цепочек" sx={{ pl: 2 }}/>
              {openMenuItems.get(DiscoveryMenu.DeleteLoops) ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={openMenuItems.get(DiscoveryMenu.DeleteLoops)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  selected={activeElementAdminPanel === ActiveElement.DeleteChain}
                  sx={{ pl: 6 }}
                  onClick={() => onClickActiveElementMenu(ActiveElement.DeleteChain)}
                >
                  <ListItemText primary="По фильтрам"/>
                </ListItemButton>
                <ListItemButton
                  selected={activeElementAdminPanel === ActiveElement.DeleteLoopsByCsv}
                  sx={{ pl: 6 }}
                  onClick={() => onClickActiveElementMenu(ActiveElement.DeleteLoopsByCsv)}
                >
                  <ListItemText primary="Из CSV"/>
                </ListItemButton>
                <ListItemButton
                  selected={activeElementAdminPanel === ActiveElement.DeleteLoopsAuto}
                  sx={{ pl: 6 }}
                  onClick={() => onClickActiveElementMenu(ActiveElement.DeleteLoopsAuto)}
                >
                  <ListItemText primary="Автоматическое"/>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.UpdateMetrics}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.UpdateMetrics)}
            >
              <ListItemText primary="Обновление метрик"/>
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={() => onClickOpenMenuHandler(DiscoveryMenu.Currencies)}>
          <ListItemText primary="Курсы"/>
          {openMenuItems.get(DiscoveryMenu.Currencies) ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openMenuItems.get(DiscoveryMenu.Currencies)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.CVS}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.CVS)}
            >
              <ListItemText primary="Загрузить CSV"/>
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={() => onClickOpenMenuHandler(DiscoveryMenu.ControlDataBase)}>
          <ListItemText primary="Управление БД"/>
          {openMenuItems.get(DiscoveryMenu.ControlDataBase) ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openMenuItems.get(DiscoveryMenu.ControlDataBase)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={activeElementAdminPanel === ActiveElement.StoppingProcesses}
              sx={{ pl: 4 }}
              onClick={() => onClickActiveElementMenu(ActiveElement.StoppingProcesses)}
            >
              <ListItemText primary="Остановить процессы"/>
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Styled.AdminPanelRight>
        {renderViewContent()}
      </Styled.AdminPanelRight>
    </Styled.AdminPanel>
  );
}

export default memo(Admin);
