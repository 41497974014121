import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from 'styled-components';
import { tableMetaData } from './constants';
import * as Styled from './TableChains.styled';
import { Paginate } from '../../../../components/Paginate';
import { Table } from '../../../../components/Table';
import { DataBody } from '../../../../components/Table/Table.types';
import { PATH } from '../../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';

import {
  setActiveFieldFiltersActions,
  valueOrderAct,
} from '../../../../redux/reducers/filters';
import { postChainsAndFilters } from '../../../../redux/reducers/filters/async';
import { chainsPaginationAct } from '../../../../redux/reducers/tabels';

import { getMetrics } from '../../../../redux/reducers/tabels/async';
import { Skeleton } from '../../../../ui/Skeleton';

function TableChains() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    currentPageChains,
    activeTypeTime,
  } = useAppSelector((state) => state.tabels);
  const {
    loadingState,
    order,
    loopChains,
    totalPages,
  } = useAppSelector((state) => state.filters);
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const {
    colors: {
      graniteGray,
      blackBlue,
      oliveGreen,
    },
  } = useTheme();

  useEffect(() => {
    dispatch(getMetrics({ authKey }));
  }, []);

  useEffect(() => {
    dispatch(postChainsAndFilters({
      authKey,
      numberPage: currentPageChains,
    }));
  }, [currentPageChains]);

  function setSorting(header: string) {
    dispatch(valueOrderAct(header));
    dispatch(setActiveFieldFiltersActions());
    dispatch(postChainsAndFilters({
      authKey,
      numberPage: currentPageChains,
    }));
  }

  function onClickNavigateInLoopPageHandler(loopChain?: DataBody) {
    navigate(PATH.loop + loopChain?.id);
  }

  return (
    <Styled.Container>
      {loadingState === 'loading'
        ? (
          <Skeleton
            width="100%"
            height="100%"
            background={blackBlue}
            borderRadius={4}
          />
        )
        : (
          <Table
            width="100%"
            height="100%"
            dataHeaders={tableMetaData}
            dataBody={loopChains}
            subName="_deviation"
            colorBodyColumnHover={graniteGray}
            colorBodyColumn={blackBlue}
            colorBodyColumnEven={oliveGreen}
            activeTypeTime={activeTypeTime}
            setSorting={setSorting}
            fillSorting={order[0]}
            isActiveSort={!!order[1]}
            setActionByRow={onClickNavigateInLoopPageHandler}
            colorSubstrate={blackBlue}
            isBorderHeader
          />
        )}
      <Styled.PaginateWrapper>
        <Paginate
          totalPages={totalPages}
          forcePage={currentPageChains}
          setActivePageAction={chainsPaginationAct}
          width="50%"
          height={45}
          pageRangeDisplayed={4}
          loadingState={loadingState}
        />
      </Styled.PaginateWrapper>
    </Styled.Container>
  );
}

export default TableChains;
