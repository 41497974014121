import React, { ChangeEvent, useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { HandySvg } from 'handy-svg';
import { useLocation } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';

import { useTheme } from 'styled-components';
import { ExchangeList } from './components/ExchangeList';
import { Header } from './components/Header';
import { LoopInfoChart } from './components/LoopInfoChart';
import { UserError } from './components/UserError';
import styles from './LoopInfo.module.scss';
import iconError from '../../assets/icons/icon.svg';
import refresh from '../../assets/icons/refresh.svg';
import start from '../../assets/icons/start.svg';
import stopParsing from '../../assets/icons/stop_btn.svg';

import { AdminCourseModal } from '../../components/AdminCourseModal';

import { Button } from '../../components/Button';
import { ButtonType } from '../../components/Button/Button.types';
import { ImageContainer } from '../../components/ImageContainer';
import { MetaTags } from '../../components/MetaTags';
import { TooltipWithElement } from '../../components/TooltipWithElement';
import { INPUT_MASKS } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';

import {
  setAmountChainActions,
  setAmountOnlineParsingActions,
  setProfitChainActions,
  setProfitOnlineParsingActions,
  setStartCurrencyChainAct,
  setStateBtnStartedAct,
  setStatusOnlineParsingAct,
  startOrStopOnlineParsingInLoopActions,
} from '../../redux/reducers/onlineParsing';

import { getOnlineParsing, stopOnlineParser } from '../../redux/reducers/onlineParsing/async';
import { IOnlineParsingMap, StateButtonParsing, TitleButtonParsing } from '../../redux/reducers/onlineParsing/types';

import { collapseTransitAndConvertStatusInTextAct } from '../../redux/reducers/tabels';
import { getChainFullInfo } from '../../redux/reducers/tabels/async';
import { CourseItem } from '../../types/CourseItem';
import { FieldInput } from '../../ui/FieldInput';
import { calculatePercent } from '../../utils/calculatePercent/calculatePercent';
import { getTimeFormating } from '../../utils/getTimeFormating/getTimeFormating';

export function LoopInfo() {
  const dispatch = useAppDispatch();
  const {
    chainFullInfo,
    loadingState,
    chainSteps,
  } = useAppSelector((state) => state.tabels);
  const {
    profit,
    amount,
    startCurrencyId,
    onlineParsing,
  } = useAppSelector((state) => state.parsing);
  const {
    authKey = '',
    userStatus,
  } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const {
    colors: {
      slateGray,
    },
  } = useTheme();
  const {
    colors: {
      arsenic,
      snowWhite,
      greenCobalt,
    },
  } = useTheme();

  const [loopId, setLoopId] = useState<string>('');
  const [activeCourseModal, setActiveCourseModal] = useState<boolean>(false);
  const [selectCourse, setSelectCourse] = useState<CourseItem>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [allPlatforms, setAllplatforms] = useState<string[]>([]);
  const [isValidationError, setIsValidationError] = useState(true);
  const [isNormalCourses, setIsNormalCourses] = useState(false);
  const [onlineParsingInLoop, setOnlineParsingInLoop] = useState<IOnlineParsingMap>();

  // Метод для установки либо суммы транзакции, либо, при включенном парсинге, может устанавливать сумму итоговой доходности
  function onChangeSetTransactionAmountOrProfitHandler(event: ChangeEvent<HTMLInputElement>, isAmount: boolean) {
    const transactionAmountOrProfit = event.target.value;

    if (INPUT_MASKS.realNonNegativeNumbers.test(transactionAmountOrProfit)) {
      dispatch(setAmountChainActions(isAmount ? transactionAmountOrProfit : null));

      if (onlineParsingInLoop?.isActive) {
        dispatch(setProfitChainActions(!isAmount ? transactionAmountOrProfit : null));
        dispatch(isAmount
          ? setAmountOnlineParsingActions({
            loopId: +loopId,
            amount: transactionAmountOrProfit,
          })
          : setProfitOnlineParsingActions({
            loopId: +loopId,
            profit: transactionAmountOrProfit,
          }),
        );
      }
    }
  }

  // Метод, который включает или выключает отслеживание в цепочки онлайн парсинга
  function onChangeTrackNewLoopWithOnlineParsingHandler() {
    if (!isNormalCourses && startCurrencyId) {
      dispatch(startOrStopOnlineParsingInLoopActions({
        loopId: +loopId,
        currencyStart: startCurrencyId,
        stateButton: StateButtonParsing.Start,
        amount: amount || null,
        profit: profit || null,
        isActive: onlineParsingInLoop ? onlineParsingInLoop.isActive : true,
        title: onlineParsingInLoop ? onlineParsingInLoop.title : TitleButtonParsing.Start,
      }));
    }
  }

  function setValueInTransactionAmountOrProfit(
    defaultValue?: string | null,
    mapProperty?: string | null,
  ) {
    const value = defaultValue ? defaultValue : '';
    const mapValue = mapProperty ? mapProperty : '';

    return onlineParsingInLoop?.isActive ? mapValue : value;
  }

  useEffect(() => {
    setLoopId(pathname.split('chain-info/')[1]);
  }, [pathname]);

  useEffect(() => {
    if (loopId !== '' && loadingState !== 'failed') {
      dispatch(getChainFullInfo({
        authKey,
        loopId: loopId || '',
      }));
      dispatch(setAmountChainActions(''));
      dispatch(setProfitChainActions(''));
    }
  }, [loopId]);

  const onActionOnlineParsing = () => {
    if (!onlineParsingInLoop)
      throw new Error('Invalid Online parser: online parser no in loop');

    if (onlineParsingInLoop.stateButton === StateButtonParsing.Start) {
      dispatch(getOnlineParsing({
        authKey,
        idChains: onlineParsingInLoop.loopId,
        profit: onlineParsingInLoop.profit,
        amount: onlineParsingInLoop.amount,
        startCurrency: onlineParsingInLoop.currencyStart,
        activeParser: onlineParsingInLoop.stateButton,
      }));
    } else if (onlineParsingInLoop?.stateButton === StateButtonParsing.Stop) {
      dispatch(stopOnlineParser({
        authKey,
        idChains: onlineParsingInLoop.loopId,
        activeParser: onlineParsingInLoop.stateButton,

        profit: onlineParsingInLoop.profit,
        amount: onlineParsingInLoop.amount,
        startCurrency: onlineParsingInLoop.currencyStart,
      }));
    }
  };

  function onClickSetActiveOnlineParsingHandler() {
    dispatch(setStateBtnStartedAct(+loopId));
    onActionOnlineParsing();
  }

  function checkDataResult(value: number | string, data?: string) {
    return value && value !== -1
      ? data
        ? data
        : value
      : 'Н/Д';
  }

  // Выключает онлайн парсинг при выходе из страницы
  useEffect(() => {
    const resetStatusParsing = () => {
      if (onlineParsingInLoop && onlineParsingInLoop?.stateButton === StateButtonParsing.Stop) {
        dispatch(setStatusOnlineParsingAct({
          loopId: +loopId,
          stateButton: StateButtonParsing.Start,
          title: TitleButtonParsing.Start,
        }));
      }
    };

    return () => {
      resetStatusParsing();
    };
  }, [pathname, onlineParsing]);

  useEffect(() => {
    if (Object.keys(chainFullInfo).length !== 0) {
      const currency = chainFullInfo?.steps[0].currency_from;
      dispatch(setStartCurrencyChainAct({
        idChains: +loopId,
        currency,
      }));
      dispatch(collapseTransitAndConvertStatusInTextAct());
      setAllplatforms(chainFullInfo.platforms);
    }
  }, [chainFullInfo]);

  useEffect(() => {
    if (chainSteps.length) {
      setIsNormalCourses(!!chainSteps.find((step) => step.rate === -1 || step.rate === 'Нет предложений'));
    }
  }, [chainSteps]);

  useEffect(() => {
    setOnlineParsingInLoop(onlineParsing.get(+loopId));
  }, [onlineParsing]);

  useEffect(() => {
    setIsValidationError(!onlineParsingInLoop?.amount && !onlineParsingInLoop?.profit);
  }, [onlineParsingInLoop]);


  return (
    <>
      <div className={styles.root} id="site">
        <MetaTags
          title={`Arbitoring - Chain ${loopId}`}
          description="Arbitoring - the best currency arbitrage in the world"
          name="Arbitoring"
          type="article"
        />

        {loadingState === 'loading' ? (
          <div className={styles.loading}>
            <PropagateLoader
              size="30px"
              color={greenCobalt}
              speedMultiplier={1}
            />
          </div>
        ) : (
          <>
            <UserError
              idChains={loopId || ''}
              isActive={isActive}
              setIsActive={setIsActive}
            />

            <AdminCourseModal
              activeCourseModal={activeCourseModal}
              setActiveCourseModal={setActiveCourseModal}
              selectCourse={selectCourse}
            />

            <Header loopId={loopId}/>

            <section className={styles.admin_info}>
              <div className={styles.admin_info_col}>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>ID:</div>
                  <div className={styles.admin_info_text}>{loopId}</div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>Была найдена</div>
                  <div className={styles.admin_info_text}>
                    {getTimeFormating({
                      date: chainFullInfo?.added,
                      compressionDate: true,
                    })}
                  </div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>Кол-во применяемых правил:</div>
                  <div className={styles.admin_info_text}>{chainFullInfo?.length}</div>
                </div>
                <Tooltip title="Какие виды валют есть в цепочке: Ф - Фиат безналичные / К - Крипто / Н - Фиат наличные">
                  <div className={styles.admin_info_wrapper}>
                    <div className={styles.admin_info_title}>Вид валюты:</div>
                    <div className={styles.admin_info_text}>{chainFullInfo?.fiat}</div>
                  </div>
                </Tooltip>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>Время выполнения:</div>
                  <div className={styles.admin_info_text}>
                    {getTimeFormating({
                      date: chainFullInfo?.loop_speed,
                      compressionDate: true,
                    })}
                  </div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>Платформы:</div>
                  <div className={styles.admin_info_text}>{allPlatforms?.join(', ')}</div>
                </div>
              </div>
              <div className={styles.admin_info_col}>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>Время прибыльности</div>
                  <div className={styles.admin_info_text}>{chainFullInfo?.profit_time_day}%</div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>
                    Время прибыльности
                    <br/>
                    (средняя продолжительность):
                  </div>
                  <div className={styles.admin_info_text}>
                    {getTimeFormating(
                      {
                        date: chainFullInfo?.profit_duration_day,
                        compressionDate: true,
                      },
                    )}
                  </div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>
                    Время прибыльности
                    <br/>
                    (отклонение):
                  </div>
                  <div className={styles.admin_info_text}>
                    {`${calculatePercent(
                      chainFullInfo?.profit_dration_day,
                      chainFullInfo?.profit_duration_deviation_day,
                    )}%`}
                  </div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>
                    Во время прибыльности
                    <br/>
                    (средний доход):
                  </div>
                  <div className={styles.admin_info_text}>
                    {checkDataResult(chainFullInfo?.mean_profit_spread_day)}
                  </div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>
                    Во время прибыльности
                    <br/>
                    (отклонение):
                  </div>
                  <div className={styles.admin_info_text}>
                    {checkDataResult(chainFullInfo?.mean_profit_spread_deviation_day)}
                  </div>
                </div>
              </div>
              <div className={styles.admin_info_col}>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>Валюта:</div>
                  <div className={styles.admin_info_text}>
                    {chainFullInfo?.currencies?.join(', ')}
                  </div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>
                    За весь период
                    <br/>
                    (средний доход):
                  </div>
                  <div className={styles.admin_info_text}>
                    {checkDataResult(chainFullInfo?.mean_spread_day)}
                  </div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>
                    За весь период
                    <br/>
                    (отклонение):
                  </div>
                  <div className={styles.admin_info_text}>
                    {checkDataResult(chainFullInfo?.mean_spread_deviation_day)}
                  </div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>Максимальный объем за круг:</div>
                  <div className={styles.admin_info_text}>
                    {checkDataResult(
                      chainFullInfo?.max_flow,
                      `${chainFullInfo?.max_flow} ${chainFullInfo?.steps?.[0]?.currency_from}`,
                    )}
                  </div>
                </div>
                <div className={styles.admin_info_wrapper}>
                  <div className={styles.admin_info_title}>Ограничение по объему торгов:</div>
                  <div className={styles.admin_info_text}>
                    {checkDataResult(
                      chainFullInfo?.max_flow,
                      `${chainFullInfo?.max_flow} ${chainFullInfo?.steps?.[0]?.currency_from}`,
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section className={styles.admin_start}>
              <div className={styles.admin_start_calculation}>
                <div className={styles.admin_start_transaction}>
                  <div className={styles.admin_start_text}>Сумма транзакции:</div>
                  <FieldInput
                    value={setValueInTransactionAmountOrProfit(amount, onlineParsingInLoop?.amount)}
                    onChange={(event) => onChangeSetTransactionAmountOrProfitHandler(event, true)}
                    isReadOnly={isNormalCourses}
                    isDisabled={onlineParsingInLoop?.stateButton === StateButtonParsing.Stop}
                    placeholder={`100 ${chainFullInfo?.steps?.[0]?.currency_from}`}
                    borderRadius={4}
                    backgroundColor={slateGray}
                    height={32}
                    width={160}
                    marginLeft={4}
                  />
                </div>
              </div>

              <div className={`${styles.admin_start_calculation} ${styles.admin_start_profit}`}>
                {onlineParsingInLoop?.isActive ? (
                  <div className={styles.admin_start_transaction}>
                    <div className={styles.admin_start_text}>Сумма в процентах:</div>
                    <input
                      type="text"
                      className={styles.admin_start_value_2}
                      placeholder="1%"
                      value={setValueInTransactionAmountOrProfit(profit, onlineParsingInLoop?.profit)}
                      onChange={(event) => onChangeSetTransactionAmountOrProfitHandler(event, false)}
                      disabled={onlineParsingInLoop?.stateButton === StateButtonParsing.Stop}
                    />
                  </div>
                ) : null}
              </div>

              {onlineParsingInLoop?.isActive && (
                <Button
                  width={132}
                  height={32}
                  onClick={onClickSetActiveOnlineParsingHandler}
                  isDisabled={isValidationError}
                  title={onlineParsingInLoop ? onlineParsingInLoop?.title : 'Запусить'}
                  image={onlineParsingInLoop?.stateButton === StateButtonParsing.Stop ? stopParsing : start}
                  imageWidth={20}
                  imageHeight={20}
                  fill={arsenic}
                  type={onlineParsingInLoop?.stateButton === StateButtonParsing.Stop ? ButtonType.TypeRed : ButtonType.TypeBlue}
                  size="regular14"
                  indent={2}
                  isImageLeft
                />
              )}

              <div
                className={styles.admin_user_error}
                onClick={() => setIsActive(true)}
              >
                <span className={styles.full_list}>
                  <TooltipWithElement
                    hint="Сообщить об ошибке"
                    element={
                      <ImageContainer
                        width={20}
                        height={18}
                        image={iconError}
                        fill={snowWhite}
                      />
                    }
                  />
                </span>
              </div>
            </section>

            <section className={styles.admin_start_choise}>
              {userStatus === 2 && (
                <>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        className={styles.FormControlLabel}
                        value="start"
                        control={(
                          <Switch
                            color="primary"
                            checked={!!onlineParsingInLoop?.isActive}
                            onChange={onChangeTrackNewLoopWithOnlineParsingHandler}
                          />
                        )}
                        label="Онлайн парсинг: "
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                  <div className={isNormalCourses ? `${styles.admin_not_normal_course_parsing}` : ''}/>
                </>
              )}

              {onlineParsingInLoop && onlineParsingInLoop?.stateButton === StateButtonParsing.Stop && (
                <div className={styles.admin_start_update}>
                  <HandySvg
                    src={refresh}
                    className={styles.icon_refresh}
                  />
                  <div className={styles.admin_start_update_text}>Обновить</div>
                </div>
              )}
            </section>

            <ExchangeList
              idChains={+loopId}
              setActiveModal={setActiveCourseModal}
              setSelectCourse={setSelectCourse}
              valueTransaction={setValueInTransactionAmountOrProfit(amount, onlineParsingInLoop?.amount)}
            />

            <LoopInfoChart onlineParsingInLoop={onlineParsingInLoop}/>
          </>
        )}
      </div>
      {/* Нужен, чтобы избежать булой полосы при скриншоте*/}
      <div className={styles.root__bottom}/>
    </>
  );
}
