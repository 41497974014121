enum EnvironmentKey {
  Prod = 'prod',
  PreProd = 'main',
  Test = 'test',
  Dev = 'dev',
}

const DEFAULT_URL: Record<EnvironmentKey, string> = {
  [EnvironmentKey.Prod]: process.env.REACT_APP_PROD || '',
  [EnvironmentKey.PreProd]: process.env.REACT_APP_PRE_PROD || '',
  [EnvironmentKey.Test]: process.env.REACT_APP_TEST || '',
  [EnvironmentKey.Dev]: process.env.REACT_APP_TEST || '',
};

const DEFAULT_URL_PARSER: Record<EnvironmentKey, string> = {
  [EnvironmentKey.Prod]: process.env.REACT_APP_PROD_ONLINE_PARSER || '',
  [EnvironmentKey.PreProd]: process.env.REACT_APP_PRE_PROD_ONLINE_PARSER || '',
  [EnvironmentKey.Test]: process.env.REACT_APP_TEST_ONLINE_PARSER || '',
  [EnvironmentKey.Dev]: process.env.REACT_APP_TEST_ONLINE_PARSER || '',
};

export const _baseURL = DEFAULT_URL[process.env.REACT_APP_CI_COMMIT_REF_NAME as EnvironmentKey || EnvironmentKey.Dev];
export const _onlineParserURL = DEFAULT_URL_PARSER[process.env.REACT_APP_CI_COMMIT_REF_NAME as EnvironmentKey || EnvironmentKey.Dev];
