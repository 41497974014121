import { Advantages } from './components/Advantages';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Possibilities } from './components/Possibilities';
import { Preview } from './components/Preview';
import { Rates } from './components/Rates';

export function Home() {
  return(
    <>
      <Header/>

      <main>
        <Preview/>
        <Advantages/>
        <Possibilities/>
        <Rates/>
      </main>

      <Footer/>
    </>
  );
}