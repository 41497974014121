import { RouteProps } from './UserPages.types';
import { PATH } from '../../../constants/constants';
import Admin from '../../../pages/Admin';
import { PasswordRecovery } from '../../../pages/Authentication/PasswordRecovery';
import { SignIn } from '../../../pages/Authentication/SignIn';
import { SignUp } from '../../../pages/Authentication/SignUp';
import { Chains } from '../../../pages/Chains';
import { Courses } from '../../../pages/Courses';
import { FavoriteChains } from '../../../pages/FavoriteChains';
import { LoopInfo } from '../../../pages/LoopInfo';
import { OnlineOperation } from '../../../pages/OnlineOperation';
import { Platform } from '../../../pages/Platform';

export const ROUTES_USER_ADMIN: RouteProps[] = [
  {
    key: PATH.courses,
    path: PATH.courses,
    element: <Courses/>,
  },
  {
    key: PATH.chains,
    path: PATH.chains,
    element: <Chains/>,
  },
  {
    key: PATH.loopId,
    path: PATH.loopId,
    element: <LoopInfo/>,
  },
  {
    key: PATH.adminPanel,
    path: PATH.adminPanel,
    element: <Admin/>,
  },
  {
    key: PATH.myChains,
    path: PATH.myChains,
    element: <FavoriteChains/>,
  },
  {
    key: PATH.onlineOperation,
    path: PATH.onlineOperation,
    element: <OnlineOperation/>,
  },
  {
    key: PATH.platform,
    path: PATH.platform,
    element: <Platform/>,
  },
];

export const ROUTES_USER_PREMIUM: RouteProps[] = [
  {
    key: PATH.chains,
    path: PATH.chains,
    element: <Chains/>,
  },
  {
    key: PATH.loopId,
    path: PATH.loopId,
    element: <LoopInfo/>,
  },
  {
    key: PATH.myChains,
    path: PATH.myChains,
    element: <FavoriteChains/>,
  },
];

export const ROUTES_USER_FREE: RouteProps[] = [
  {
    key: PATH.chains,
    path: PATH.chains,
    element: <Chains/>,
  },
  {
    key: PATH.loopId,
    path: PATH.loopId,
    element: <LoopInfo/>,
  },
];

export const ROUTES_AUTHENTICATION: RouteProps[] = [
  {
    key: PATH.signIn,
    path: PATH.signIn,
    element: <SignIn/>,
  },
  {
    key: PATH.signUp,
    path: PATH.signUp,
    element: <SignUp/>,
  },
  {
    key: PATH.passwordRecovery,
    path: PATH.passwordRecovery,
    element: <PasswordRecovery/>,
  },
];