import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '.';

export const logOut = (state: AuthState) => {
  state.isLogged = false;
  state.userStatus = 0;
  state.login = '';
  state.authKey = undefined;
};

export const onEmailUser = (
  state: AuthState,
  { payload }: PayloadAction<string>,
) => {
  state.emailUser = payload;
};

export const setIsTransitionPinCodeInFalse = (
  state: AuthState,
) => {
  state.isTransitionPinCode = false;
  state.isTransitionPinCodeRecovery = false;
  state.isValidPinCode = false;
};

export const setIsRegisterStata = (
  state: AuthState,
  { payload }: PayloadAction<boolean>,
) => {
  state.isRegister = payload;
};

export const setRegisterRequestValidMessageReset = (
  state: AuthState,
) => {
  state.registerRequestValidMessage = null;
};

export const setIsValidPinCodeInFalse = (
  state: AuthState,
) => {
  state.isValidPinCode = false;
};