export const INPUT_MASKS = {
  // Натуральные числа
  number: /^\d+$/,
  // Действительные числа
  realNumbers: /^[0-9-]+$/,
  // Вещественные числа
  floatNumbers: /^[+-]?(\d+\.?\d*|\.\d+|\d+)([eE][+-]?\d+)?$|^-$/,
  password: /^[a-zA-Z0-9!@#$%^&*]+$/,
  email: /^[a-zA-Z0-9.]+@[a-zA-Z0-9\s-]+\.[A-Za-z]{2,}$/,
  nonNegativeFloatNumbers: /^\d*\.?\d+$/,
  lettersAndNumbers: /^[a-zA-Z0-9]$/,
  // Вещественные не отрицательные числа с вожможностью пустой строки и ограничением в 20 символов
  realNonNegativeNumbers: /^(?!^\.)\d{0,10}(\.)?\d{0,10}$/,

  leastOneUppercaseLetter: /[A-Z]/,
  leastOneLowercaseLetter: /[a-z]/,
  leastOneNumber: /\d/,
  leastOneSpecialCharacter: /[!@#$%^&*]/,
};

export const Masks = {
  HyphensAndUnderscoresWithSmallLetters: /([-_][a-z])/ig,
  Uppercase: /([A-Z])/g,
  snakeCase: /(_\w)/g,
};

export const SCREEN = {
  desktopsLarge: '1075px',
  desktops: '1025px',
  tabletsLarge: '820px',
  tablets: '769px',
  landscapePhones: '578px',
  mobileM: '375px',
};

export const PATH = {
  loop: '/chains/chain-info/',
  chains: '/chains',
  courses: '/courses',
  signIn: '/signIn',
  signUp: '/signUp',
  passwordRecovery: '/passwordRecovery',
  default: '/',
  loopId: '/chains/chain-info/:loopId',
  myChain: '/my-chains/:myChains',
  myChains: '/my-chains',
  platform: '/platforms/:platformId',
  platforms: '/platforms/',
  adminPanel: '/admin-panel',
  onlineOperation: '/online-operation',
};

export const DASH = '-';
