import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { DataMetric } from './types';
import { calculateDataTimeLoopSpeed } from '../../../utils/calculateDataTimeLoopSpeed/calculateDataTimeLoopSpeed';
import {
  deleteFieldWithNull,
} from '../../../utils/deleteFieldWithNull/deleteFieldWithNull';
import { parseDataLength } from '../../../utils/parseDataLength/parseDataLength';
import { parseDataRangeTime } from '../../../utils/parseDataRangeTime/parseDataRangeTime';
import { RootState } from '../../store';

// Метод, устанавливающий активные фильтры в таблице курсов
export const setCoursesFilters = createAsyncThunk(
  'filters/putApplyFilters',
  async (
    { authKey }: { authKey: string },
    { getState },
  ) => {
    const { filters } = getState() as RootState;
    const {
      coursesActiveFilters: {
        coursesRuleNumber,
        coursesPlatform,
        coursesCurrency,
        coursesMethod,
        coursesSortField,
        coursesSortDirection,
        coursesRateFirst,
        coursesRateSecond,
      },
    } = filters;

    // В бэке на данный момент нет проверки на максимальное занчение
    const maxValueCourse = 10000000000000;

    const activeFilters = {
      rule_number: coursesRuleNumber,
      platform: coursesPlatform,
      currency: coursesCurrency,
      method: coursesMethod,
      sort_field: coursesSortField,
      sort_direction: coursesSortDirection,
      rate: [
        coursesRateFirst ? coursesRateFirst : 0,
        coursesRateSecond ? coursesRateSecond : maxValueCourse,
      ],
    };

    const { data } = await axios.put('/apply-filters', { ...activeFilters }, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);

export const getClearFilters = createAsyncThunk(
  'filters/getClearFilters',
  async ({
    authKey,
  }: {
    authKey: string,
  }) => {
    const { data } = await axios.get('/clear-filters', {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const getClearLoopFilters = createAsyncThunk(
  'filters/getClearLoopFilters',
  async ({
    authKey,
  }: {
    authKey: string,
  }) => {
    const { data } = await axios.get('/clear-loop-filters', {
      headers: {
        key: authKey,
      },
    });
    return data;
  },
);

export const postChainsAndFilters = createAsyncThunk(
  'filters/postChainsAndFilters',
  async (
    {
      authKey,
      numberPage,
    }: {
      authKey: string,
      numberPage: number,
    },
    { getState },
  ) => {
    const { filters } = getState() as RootState;
    const {
      activeFiltersChains,
    } = filters;

    const {
      loopId, // ID цепочки
      order, // Сортировка по столбцу [название поля как в БД, по убыванию/возрастанию (boolean)]
      valueRangeTime, // Время выполнения
      valueRangeLoopSpeed, // Время существования цепочки
      valueRangeRegulation, // Количество правил
      isModeFilter, // Строгий режим
      modeChainsStatus, // Статусы цепочек
      valueRangeRule, // Средний доход за весь период (день)
      valueRangeRuleWeek, // Средний доход за весь период (неделя)
      valueRangeRuleMonth, // Средний доход за весь период (месяц)
      selectedCurrency, // Выбранные валюты
      selectedCourses, // Выбранные типы валют
      selectedPlatforms, // Выбранные платформы
      selectedMethods, // Выбранные метода
      valueRangeTimeProfit, // Время прибыльности (день)
      valueRangeTimeProfitWeek, // Время прибыльности (неделя)
      valueRangeTimeProfitMonth, // Время прибыльности (месяц)
      valueRangeTimeProfitPercent, // Времени прибыльности в % (день)
      valueRangeTimeProfitPercentWeek, // Времени прибыльности в % (неделя)
      valueRangeTimeProfitPercentMonth, // Времени прибыльности в % (месяц)
      currentProfitability, // Текущая прибыльность
      averageIncomeDuringProfitability, // Во время прибыльность (день)
      averageIncomeDuringProfitabilityWeek, // Во время прибыльность (неделя)
      averageIncomeDuringProfitabilityMonth, // Во время прибыльность (месяц)
    } = activeFiltersChains;

    function getFiledNameFromMetric(metric: DataMetric[]) {
      return metric.map(({ name }) => name);
    }

    function getFiledIdFromMetric(metric: DataMetric[]) {
      return metric.map(({ id }) => id);
    }

    function getFiledTitleFromMetric(metric: DataMetric[]) {
      return metric.map(({ title }) => title);
    }

    const listFilters = deleteFieldWithNull({
      order,
      id: loopId || null,
      last_speed_l: parseDataRangeTime(valueRangeTime[0]) ? parseDataRangeTime(valueRangeTime[0]) : null,
      last_speed_r: parseDataRangeTime(valueRangeTime[1]) ? parseDataRangeTime(valueRangeTime[1]) : null,
      added_l: calculateDataTimeLoopSpeed(valueRangeLoopSpeed[0]) ? calculateDataTimeLoopSpeed(valueRangeLoopSpeed[0]) : null,
      added_r: calculateDataTimeLoopSpeed(valueRangeLoopSpeed[1]) ? calculateDataTimeLoopSpeed(valueRangeLoopSpeed[1]) : null,
      profit_time_day_l: valueRangeTimeProfitPercent[0] ? valueRangeTimeProfitPercent[0] : null,
      profit_time_day_r: valueRangeTimeProfitPercent[1] !== 100 ? valueRangeTimeProfitPercent[1] : null,
      profit_time_week_l: valueRangeTimeProfitPercentWeek[0] ? valueRangeTimeProfitPercentWeek[0] : null,
      profit_time_week_r: valueRangeTimeProfitPercentWeek[1] !== 100 ? valueRangeTimeProfitPercentWeek[1] : null,
      profit_time_month_l: valueRangeTimeProfitPercentMonth[0] ? valueRangeTimeProfitPercentMonth[0] : null,
      profit_time_month_r: valueRangeTimeProfitPercentMonth[1] !== 100 ? valueRangeTimeProfitPercentMonth[1] : null,
      mean_spread_day_l: valueRangeRule[0] !== -30 ? valueRangeRule[0] : null,
      mean_spread_day_r: valueRangeRule[1] !== 30 ? valueRangeRule[1] : null,
      mean_spread_week_l: valueRangeRuleWeek[0] !== -30 ? valueRangeRuleWeek[0] : null,
      mean_spread_week_r: valueRangeRuleWeek[1] !== 30 ? valueRangeRuleWeek[1] : null,
      mean_spread_month_l: valueRangeRuleMonth[0] !== -30 ? valueRangeRuleMonth[0] : null,
      mean_spread_month_r: valueRangeRuleMonth[1] !== 30 ? valueRangeRuleMonth[1] : null,
      platforms: getFiledNameFromMetric(selectedPlatforms),
      currencies: getFiledNameFromMetric(selectedCourses),
      currency_types: getFiledTitleFromMetric(selectedCurrency),
      methods: getFiledIdFromMetric(selectedMethods),
      profit_duration_day_l: valueRangeTimeProfit[0] * 60 === 0 ? null : valueRangeTimeProfit[0] * 60,
      profit_duration_day_r: valueRangeTimeProfit[1] * 60 === 7200 ? null : valueRangeTimeProfit[1] * 60,
      profit_duration_week_l: valueRangeTimeProfitWeek[0] * 60 === 0 ? null : valueRangeTimeProfitWeek[0] * 60,
      profit_duration_week_r: valueRangeTimeProfitWeek[1] * 60 === 7200 ? null : valueRangeTimeProfitWeek[1] * 60,
      profit_duration_month_l: valueRangeTimeProfitMonth[0] * 60 === 0 ? null : valueRangeTimeProfitMonth[0] * 60,
      profit_duration_month_r: valueRangeTimeProfitMonth[1] * 60 === 7200 ? null : valueRangeTimeProfitMonth[1] * 60,
      mean_profit_spread_day_l: averageIncomeDuringProfitability[0] !== -10 ? averageIncomeDuringProfitability[0] : null,
      mean_profit_spread_day_r: averageIncomeDuringProfitability[1] !== 30 ? averageIncomeDuringProfitability[1] : null,
      mean_profit_spread_week_l: averageIncomeDuringProfitabilityWeek[0] !== -10 ? averageIncomeDuringProfitabilityWeek[0] : null,
      mean_profit_spread_week_r: averageIncomeDuringProfitabilityWeek[1] !== 30 ? averageIncomeDuringProfitabilityWeek[1] : null,
      mean_profit_spread_month_l: averageIncomeDuringProfitabilityMonth[0] !== -10 ? averageIncomeDuringProfitabilityMonth[0] : null,
      mean_profit_spread_month_r: averageIncomeDuringProfitabilityMonth[1] !== 30 ? averageIncomeDuringProfitabilityMonth[1] : null,
      last_spread_l: currentProfitability[0] !== -10 ? currentProfitability[0] : null,
      last_spread_r: currentProfitability[1] !== 30 ? currentProfitability[1] : null,
      length_l: parseDataLength(valueRangeRegulation[0]) ? parseDataLength(valueRangeRegulation[0]) : null,
      length_r: parseDataLength(valueRangeRegulation[1]) ? parseDataLength(valueRangeRegulation[1]) : null,
      strict_filter: isModeFilter,
      status_list: modeChainsStatus.length ? modeChainsStatus : null,
    });

    const { data } = await axios.post(`/loops?current_page=${numberPage}`, { ...listFilters }, {
      headers: {
        key: authKey,
      },
    });

    return data;
  },
);
