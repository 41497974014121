import React from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './TableState.styled';
import refresh from '../../../../../../assets/icons/refresh.svg';
import { Button } from '../../../../../../components/Button';
import { ImageContainer } from '../../../../../../components/ImageContainer';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { clearFiltersChainsAction } from '../../../../../../redux/reducers/filters';
import { postChainsAndFilters } from '../../../../../../redux/reducers/filters/async';
import { resetSelectedPageInFirstAction } from '../../../../../../redux/reducers/tabels';

export function TableState() {
  const dispatch = useAppDispatch();
  const {
    currentPageChains,
  } = useAppSelector((state) => state.tabels);
  const {
    loopsCount,
    loopsСountSubscribe,
  } = useAppSelector((state) => state.filters);
  const {
    authKey = '',
    userStatus,
  } = useAppSelector((state) => state.auth);

  const {
    colors: {
      snowWhite,
    },
  } = useTheme();

  function onClickUpdateChainsHandler() {
    dispatch(postChainsAndFilters({
      authKey,
      numberPage: currentPageChains,
    }));
  }

  function onClickResetFiltersChainsHandler() {
    dispatch(clearFiltersChainsAction());
    dispatch(postChainsAndFilters({
      authKey,
      numberPage: currentPageChains,
    }));
    dispatch(resetSelectedPageInFirstAction());
  }

  return (
    <Styled.ChainsButtonsList>
      <Button
        width={120}
        height={32}
        onClick={onClickUpdateChainsHandler}
        image={refresh}
        imageWidth={18}
        imageHeight={18}
        fill={snowWhite}
        indent={1}
        isImageLeft
        title='Обновить'
        size='regular14'
      />

      <Styled.ChainsClearButton>
        <Button
          width={150}
          height={32}
          onClick={onClickResetFiltersChainsHandler}
          title='Сбросить фильтры'
          size='regular14'
        />
      </Styled.ChainsClearButton>

      <Styled.ChainsTotalText>
        {userStatus === 3
          ? `Доступно ${loopsCount} из ${loopsСountSubscribe}`
          : `Найдено ${loopsCount}`
        }
      </Styled.ChainsTotalText>
    </Styled.ChainsButtonsList>
  );
}
