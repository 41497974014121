import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './SampleTable.styled';
import { SampleTableProps } from './SampleTable.types';
import { Paginate } from '../../../../components/Paginate';
import { Table } from '../../../../components/Table';
import { DataBody } from '../../../../components/Table/Table.types';
import { PATH } from '../../../../constants/constants';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { setChoisePageDirectoriesAct } from '../../../../redux/reducers/navigation';
import { Skeleton } from '../../../../ui/Skeleton';

export function SampleTable({
  body,
  totalPages,
  dataMetaHeaders,
  isNavigatePlatform = false,
}: SampleTableProps) {
  const navigate = useNavigate();
  const {
    choisePageDirectories,
    activeElementAdminPanel,
  } = useAppSelector((state) => state.navigation);

  const {
    colors: {
      graniteGray,
      blackBlue,
      oliveGreen,
    },
  } = useTheme();

  const {
    loadingState,
  } = useAppSelector((state) => state.tabels);

  function onClickNavigateInLoopPageHandler(platformKey?: DataBody) {
    navigate(PATH.platforms + platformKey?.id);
  }

  return (
    <>
      <Styled.SampleTableContainer>
        {loadingState === 'loading'
          ? (
            <Skeleton
              width="95%"
              height="75vh"
              background={blackBlue}
              borderRadius={4}
            />
          ) : (
            <Table
              width="95%"
              height="75vh"
              dataHeaders={dataMetaHeaders}
              dataBody={body}
              colorBodyColumnHover={graniteGray}
              colorBodyColumn={blackBlue}
              colorBodyColumnEven={oliveGreen}
              colorSubstrate={blackBlue}
              setActionByRow={isNavigatePlatform ? onClickNavigateInLoopPageHandler : undefined}
              isBorderHeader
            />
          )
        }

        <Styled.PaginateContainer>
          <Paginate
            width="50%"
            height="5vh"
            totalPages={totalPages}
            forcePage={choisePageDirectories.get(activeElementAdminPanel) || 1}
            setActivePageAction={setChoisePageDirectoriesAct}
            loadingState={loadingState}
          />
        </Styled.PaginateContainer>
      </Styled.SampleTableContainer>
    </>
  );
}
