export interface IDataChainsTime {
  mean_profit_spread: number;
  mean_profit_spread_deviation: number;
  mean_spread: number;
  mean_spread_deviation: number;
  perspective_integral: number | null;
  profit_duration: number;
  profit_duration_deviation: number;
  profit_time: number;
}

export interface DataChainsFull {
  [key: string]: string | string[] | number | boolean | undefined | null,

  added: number;
  currencies: string[];
  currency_from?: string;
  currency_to?: string;
  fiat: boolean | null;
  id: number;
  length: number;
  loop_speed: number;
  max_flow: string;
  platform_from?: string;
  platform_to?: string;
  platforms: string[];
  spread: string | null;
  status: string;

  mean_profit_spread?: number;
  mean_profit_spread_deviation?: number;
  mean_spread?: number;
  mean_spread_deviation?: number;
  perspective_integral?: number | null;
  profit_duration?: number;
  profit_duration_deviation?: number;
  profit_time?: number;
}

export type ActiveFilterChains = {
  loopId: string,
  selectedPlatforms: DataMetric[],
  selectedCourses: DataMetric[],
  selectedCurrency: DataMetric[],
  selectedMethods: DataMetric[],
  ruleValue: number,
  valueRangeRegulation: number[],
  valueRangeRule: number[],
  valueRangeRuleWeek: number[],
  valueRangeRuleMonth: number[],
  valueRangeTimeProfit: number[],
  valueRangeTimeProfitWeek: number[],
  valueRangeTimeProfitMonth: number[],
  valueRangeTimeProfitPercent: number[],
  valueRangeTimeProfitPercentWeek: number[],
  valueRangeTimeProfitPercentMonth: number[],
  valueRangeTime: number[],
  valueRangeLoopSpeed: number[],
  order: [string, boolean] | string,
  isModeFilter: boolean,
  modeChainsStatus: number[],
  currentProfitability: number[],
  averageIncomeDuringProfitability: number[],
  averageIncomeDuringProfitabilityWeek: number[],
  averageIncomeDuringProfitabilityMonth: number[],
}

export type DataMetric = {
  id: number,
  name: string,
  title?: string,
}

export enum CoursesSorting {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export type CoursesActiveFilters = {
  coursesRuleNumber: number,
  coursesPlatform: string,
  coursesCurrency: string,
  coursesRateFirst: number,
  coursesRateSecond: number,
  coursesMethod: string,
  coursesSortField: string,
  coursesSortDirection: CoursesSorting,
}
