import { useEffect, useState } from 'react';
import * as Styled from './LoopInfoChart.styled';
import { LoopInfoChartTypes } from './LoopInfoChart.types';
import { Button } from '../../../../components/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { graphTime, isoDatetimeRegular } from '../../constants';
import { ActiveTimeGraph, GraphChain, GraphTime } from '../../LoopInfo.types';
import { Chart } from '../Chart';

export function LoopInfoChart({
  onlineParsingInLoop,
}: LoopInfoChartTypes) {
  const {
    timeOnlineParsing,
    spreadOnlineParsing,
  } = useAppSelector((state) => state.parsing);
  const {
    chainFullInfo,
  } = useAppSelector((state) => state.tabels);

  const [activeGraph, setActiveGraph] = useState(graphTime);
  const [activeTimeGraph, setActiveTimeGraph] = useState<ActiveTimeGraph>(ActiveTimeGraph.Minute);
  const [graphSpreadArr, setGraphSpreadArr] = useState<number[]>([]);
  const [graphTimeArr, setGraphTimeArr] = useState<string[]>([]);

  function onClickSetActiveTypeTimeGraphHandler(graphTypeTime: GraphTime) {
    setActiveGraph(activeGraph.map((item) => {
      if (item.id === graphTypeTime.id) {
        return {
          ...graphTypeTime,
          isSelected: true,
        };
      }
      return {
        ...item,
        isSelected: false,
      };
    }));
    setActiveTimeGraph(graphTypeTime.id);
  }

  const formatDateTime = (string: string) => {
    if (string === '') {
      return string;
    }
    const matches = string.match(isoDatetimeRegular);

    if (!matches!.groups)
      throw new Error('Invalid groups by time format');

    const year = matches!.groups!.year;
    const month = matches!.groups!.month;
    const day = matches!.groups!.day;
    const hours = matches!.groups!.hours;
    const minutes = matches!.groups!.minutes;

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  };


  // Метод, который устанавливает данные графика в зависимости от временного промежутка
  function onDataGraph() {
    setGraphSpreadArr([]);
    setGraphTimeArr([]);

    // Данные графика секунд, на данный момент строются на фронте, а не приходят с бэка
    if (activeTimeGraph === ActiveTimeGraph.Second) {
      spreadOnlineParsing.forEach((item) => setGraphSpreadArr((prev) => [...prev, item]));
      timeOnlineParsing.forEach((item) => setGraphTimeArr((prev) => [...prev, item]));
    } else if (chainFullInfo && chainFullInfo.graph) {
      chainFullInfo.graph[activeTimeGraph].forEach((item: GraphChain) => {
        setGraphSpreadArr((prev) => [...prev, item.spread]);
        setGraphTimeArr((prev) => [...prev, formatDateTime(item.time)]);
      });
    }
  }

  useEffect(() => {
    onDataGraph();
  }, [chainFullInfo, activeTimeGraph]);

  return (
    <Styled.Container>
      <Styled.ChartTitle>
        График изменения доходности цепочки
      </Styled.ChartTitle>
      <Styled.ButtonsContainer>
        {activeGraph.map((graphTypeTime) => (
          <Styled.ButtonContainer key={graphTypeTime.id}>
            <Button
              width={80}
              height={35}
              onClick={() => onClickSetActiveTypeTimeGraphHandler(graphTypeTime)}
              type={ButtonType.TypeWhite}
              title={graphTypeTime.title}
              isDisabled={graphTypeTime.isDisabled}
              isSelected={graphTypeTime.isSelected}
              size="regular14"
            />
          </Styled.ButtonContainer>
        ))}
      </Styled.ButtonsContainer>
      <Chart
        labels={onlineParsingInLoop && activeTimeGraph === ActiveTimeGraph.Second ? timeOnlineParsing : graphTimeArr}
        data={onlineParsingInLoop && activeTimeGraph === ActiveTimeGraph.Second ? spreadOnlineParsing : graphSpreadArr}
      />
    </Styled.Container>
  );
}
