import { useState } from 'react';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './Header.styled';
import { HeaderProps } from './Header.types';
import bell from '../../../../assets/icons/bell.svg';
import arrow from '../../../../assets/icons/paginationArrow.svg';
import star from '../../../../assets/icons/star.svg';

import { Button } from '../../../../components/Button';
import { ButtonType } from '../../../../components/Button/Button.types';
import { ImageContainer } from '../../../../components/ImageContainer';
import { PATH } from '../../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { postAddFavoriteChain, postFavoriteChains } from '../../../../redux/reducers/tabels/async';
import { ButtonContainer } from '../../../../ui/ButtonContainer';

export function Header({
  loopId,
}: HeaderProps) {
  const {
    colors: {
      snowWhite,
      grayBlue,
    },
  } = useTheme();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);


  const [isActive, setIsActive] = useState(false);
  function onClickBackToLoopsPageHandler() {
    navigate(PATH.chains);
  }

  function onClickAddFavoriteChainsHandler() {
    setIsActive((prev) => !prev);
    dispatch(postAddFavoriteChain({
      authKey,
      idLoop: loopId,
    }));
  }

  const watermarkText = 'arbitoring.com';

  function onCLickGetScreenShotHandler() {
    const body = document.querySelector('body');
    if (!body) return;

    // Generate screenshot
    html2canvas(body, {
      scale: 1,
    })
      .then(canvas => {
        // Convert canvas to blob
        canvas.toBlob((blob) => {
          if (!blob) {
            throw new Error('Failed to generate blob from body');
          }

          const screenshotImage = new Image();
          screenshotImage.src = URL.createObjectURL(blob);

          screenshotImage.onload = async () => {
            const cropOffset = 0;
            const canvasElem = document.createElement('canvas');
            canvasElem.width = screenshotImage.width;
            canvasElem.height = screenshotImage.height - cropOffset;

            const context = canvasElem.getContext('2d');
            if (!context) {
              return;
            }

            context.drawImage(
              screenshotImage,
              0,  // начальная координата x
              0,  // начальная координата y
              screenshotImage.width,  // ширина исходного изображения
              screenshotImage.height,  // высота исходного изображения
              0,  // целевая координата x
              -cropOffset,  // целевая координата y (обрезка снизу)
              screenshotImage.width,  // ширина целевого изображения
              screenshotImage.height, // высота целевого изображения
            );

            // Add watermark text
            context.font = '30px Arial';
            context.fillStyle = 'white';

            const textMetrics = context.measureText(watermarkText);

            // Calculate center coordinates
            const x = (canvasElem.width - textMetrics.width) / 2;
            const y = canvas.height - 855;

            // Write text to canvas
            context.fillText(watermarkText, x, y);

            canvasElem.toBlob(function(finalBlob) {
              if (finalBlob) {
                // Code to download image
                const link = document.createElement('a');
                link.href = URL.createObjectURL(finalBlob);
                link.download = `Chains-${loopId}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            });
          };
        });
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }

  return (
    <Styled.Container>
      <Button
        width={160}
        height={32}
        onClick={onClickBackToLoopsPageHandler}
        type={ButtonType.TypeBorder}
        image={arrow}
        isImageLeft
        imageHeight={15}
        imageWidth={15}
        indent={2}
        title="Список цепочек"
        size="regular14"
        fill={snowWhite}
        fillHover={snowWhite}
        fillActive={grayBlue}
      />

      <Styled.Title>
        Цепочка № {loopId}
      </Styled.Title>

      <Styled.ActionsContainer>
        <ButtonContainer marginLeft={4}>
          <Button
            width={170}
            height={32}
            onClick={onCLickGetScreenShotHandler}
            title="Сделать скриншот"
            size="regular14"
            type={ButtonType.TypeBorder}
          />
        </ButtonContainer>

        <div onClick={onClickAddFavoriteChainsHandler}>
          <ImageContainer
            width={16}
            height={16}
            image={star}
            marginRight={2}
          />
        </div>

        <ImageContainer
          width={16}
          height={16}
          image={bell}
        />
      </Styled.ActionsContainer>
    </Styled.Container>
  );
}