import * as Styled from './AllFilterChains.styled';
import { FilterCheckbox } from './components/FilterCheckbox';
import { FilterGroupWrapper } from './components/FilterGroupWrapper';
import { FilterInput } from './components/FilterInput';
import { FilterMetric } from './components/FilterMetric';
import { FilterRangeSlider } from './components/FilterRangeSlider';
import { FilterRangeSliderMarks } from './components/FilterRangeSliderMarks';
import { FilterSwitch } from './components/FilterSwitch';
import {
  marks,
  marksRule,
  marksTimeExistence, methodMainElements,
  viewCurrencyFilter,
} from './constants';

import refresh from '../../../../../../assets/icons/refresh.svg';
import { Close } from '../../../../../../components/Close';
import { FilterCheckboxWrapper } from '../../../../../../components/FilterCheckboxWrapper';
import { FilterWithDropDown } from '../../../../../../components/FilterWithDropDown';
import { ImageContainer } from '../../../../../../components/ImageContainer';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';

import {
  clearFiltersChainsAction,
  onChangeLoopSpeedAction,
  onChangeRegulationAction,
  onChangeRuleAction,
  onChangeRuleMonthAction,
  onChangeRuleWeekAction,
  onChangeTimeAction,
  onChangeTimeProfitAction,
  onChangeTimeProfitMonthAction,
  onChangeTimeProfitPercentAction,
  onChangeTimeProfitPercentMonthAction,
  onChangeTimeProfitPercentWeekAction,
  onChangeTimeProfitWeekAction,
  onSelectAllCourseAction,
  onSelectAllCurrencyAction,
  onSelectAllPlatformAction,
  onSelectCourseAction,
  onSelectCurrencyAction,
  onSelectPlatformAction,
  resetIndispensableFiltersAction,
  setAverageIncomeDuringProfitabilityAction,
  setAverageIncomeDuringProfitabilityMonthAction,
  setAverageIncomeDuringProfitabilityWeekAction,
  setCurrentProfitabilityAction,
  setActiveFieldFiltersActions,
  setIsModeFilterAct,
  setModeChainsStatusAction,
  valueLoopIdAction,
  onSetSelectedMethodsAction, onDeleteSelectedMethodsAction,
} from '../../../../../../redux/reducers/filters';

import { postChainsAndFilters } from '../../../../../../redux/reducers/filters/async';

import './AllfilterChains.scss';
import { setIsOpenModalFiltersAction } from '../../../../../../redux/reducers/navigation';
import { chainsPaginationAct, resetSelectedPageInFirstAction } from '../../../../../../redux/reducers/tabels';

// Данный компонент отвечает за работу всех филтров на странице
// Для расришения, необходимо взять один из готовых шаблонов, и передать туда нужные свойства
// Всю логику работы фильтров НЕОБХОДИМО прописывать в редуксе в filters. Необходимо создать state,
// который привязывается к выбранным элементам, и action, что добовляет/удаляет элементы. В зависимости от шаблона
// может использоватся несколько действий, Для уточнения зайди в шаблон, там есть комментарии о том, что за что отвечает
// Нужно будет прокинуть новый стейт:
// в стейт activeFiltersChains - отвечает за активные фильтры, те что уже примененты
// в действие clearFiltersChains - овечает за сброс значений по умолчанию
// в действие setActiveFieldFilters - устанавливает в стейт активные фильтры
// в действие resetIndispensableFilters - сбрасывает непримененные фильтры
// в асиннхронный запрос postChainsAndFilters - посылает данные на бэк и применяет фильтрацию

export function AllFilterChains() {
  const dispatch = useAppDispatch();
  const {
    selectedCourses,
    selectedPlatforms,
    selectedCurrency,
    selectedMethods,
    valueRangeRule,
    valueRangeTimeProfit,
    valueRangeTimeProfitWeek,
    valueRangeTimeProfitMonth,
    valueRangeTimeProfitPercent,
    valueRangeTimeProfitPercentWeek,
    valueRangeTimeProfitPercentMonth,
    valueRangeTime,
    valueRangeRegulation,
    valueRangeLoopSpeed,
    loopId,
    valueRangeRuleWeek,
    valueRangeRuleMonth,
    isModeFilter,
    modeChainsStatus,
    currentProfitability,
    averageIncomeDuringProfitability,
    averageIncomeDuringProfitabilityWeek,
    averageIncomeDuringProfitabilityMonth,
  } = useAppSelector((state) => state.filters);
  const {
    currentPageChains,
    loadingState,
    allPlatforms,
    allCurrencies,
    allMethods,
  } = useAppSelector((state) => state.tabels);
  const {
    authKey = '',
  } = useAppSelector((state) => state.auth);
  const {
    isOpenModalFilters,
  } = useAppSelector((state) => state.navigation);

  function onClickCloseModalWindowHandler() {
    dispatch(setIsOpenModalFiltersAction());
    dispatch(resetIndispensableFiltersAction());
  }

  function onClickResetFilterHandler() {
    dispatch(clearFiltersChainsAction());
    dispatch(postChainsAndFilters({
      authKey,
      numberPage: currentPageChains,
    }));
    dispatch(setIsOpenModalFiltersAction());
  }

  function onClickApplyFilterHandler() {
    dispatch(setIsOpenModalFiltersAction());
    dispatch(chainsPaginationAct(1));
    dispatch(setActiveFieldFiltersActions());
    dispatch(postChainsAndFilters({
      authKey,
      numberPage: currentPageChains,
    }));
    dispatch(resetSelectedPageInFirstAction());
  }

  return (
    <Styled.AllFilters isActive={isOpenModalFilters}>
      <Styled.ModalSubstrate onClick={onClickCloseModalWindowHandler}/>
      <Styled.AllFiltersWrapper>
        <Styled.AllFiltersHeader>
          <Styled.AllFiltersTitle>Все фильтры</Styled.AllFiltersTitle>
          <Close
            width={26}
            height={26}
            onClick={onClickCloseModalWindowHandler}
          />
        </Styled.AllFiltersHeader>
        {loadingState !== 'loading'
          ? (
            <Styled.AllFiltersBody>
              <FilterInput
                title="ID"
                placeholder="ID цепочки"
                value={loopId}
                setStateValueAction={valueLoopIdAction}
              />

              <FilterSwitch
                hint="При включенном жестком фильтре показываются только те цепочки, которые состоят только из выбранных валют-платформ и методов. При выключенном жестком фильтре показываются цепочки, которые содержат выбранные валюты-платформы и методы, но не ограничиваются ими"
                textSwitch="Строгий режим"
                isChecked={isModeFilter}
                setStateAction={setIsModeFilterAct}
              />

              <FilterMetric
                title="Валюты"
                allMetrics={allCurrencies}
                selectedItems={selectedCourses}
                setStateMetric={onSelectCourseAction}
                setStateAllMetric={onSelectAllCourseAction}
              />

              <FilterMetric
                title="Платформы"
                allMetrics={allPlatforms}
                selectedItems={selectedPlatforms}
                setStateMetric={onSelectPlatformAction}
                setStateAllMetric={onSelectAllPlatformAction}
              />

              <FilterWithDropDown
                title="Методы"
                dataList={allMethods}
                placeholder="Введите название метода"
                selectedItems={selectedMethods}
                setStateValueAction={onSetSelectedMethodsAction}
                deleteItemsAction={onDeleteSelectedMethodsAction}
                mainElementsList={methodMainElements}
              />

              <FilterMetric
                title="Вид валюты"
                allMetrics={viewCurrencyFilter}
                selectedItems={selectedCurrency}
                setStateMetric={onSelectCurrencyAction}
                setStateAllMetric={onSelectAllCurrencyAction}
                isShowAll={false}
                isRightActions={true}
              />

              <FilterRangeSliderMarks
                title="Количество правил"
                minValue={2}
                maxValue={10}
                marks={marksRule}
                value={valueRangeRegulation}
                setStateValueAction={onChangeRegulationAction}
              />

              <FilterRangeSliderMarks
                title="Время выполнения"
                minValue={0}
                maxValue={7}
                marks={marks}
                value={valueRangeTime}
                setStateValueAction={onChangeTimeAction}
              />

              <FilterRangeSliderMarks
                title="Время существования цепочки"
                minValue={0}
                maxValue={4}
                marks={marksTimeExistence}
                value={valueRangeLoopSpeed}
                setStateValueAction={onChangeLoopSpeedAction}
              />

              <FilterRangeSlider
                title="Текущая прибыльность"
                minValue={-10}
                maxValue={30}
                step={0.01}
                value={currentProfitability}
                setStateValueAction={setCurrentProfitabilityAction}
                signatureValueEndLeft="%"
                signatureValueEndRight="%"
                signatureValueEndLeftBorder="% и менее"
                signatureValueEndRightBorder="% и более"
                signatureValueStartRightBorder="+"
              />

              <FilterGroupWrapper
                title="Средний доход за весь период"
                formElements={
                  <>
                    <FilterRangeSlider
                      title="День"
                      minValue={-30}
                      maxValue={30}
                      step={0.01}
                      value={valueRangeRule}
                      setStateValueAction={onChangeRuleAction}
                      signatureValueEndLeft="%"
                      signatureValueEndRight="%"
                      signatureValueEndLeftBorder="% и менее"
                      signatureValueEndRightBorder="% и более"
                      signatureValueStartRightBorder="+"
                    />

                    <FilterRangeSlider
                      title="Неделя"
                      minValue={-30}
                      maxValue={30}
                      step={0.01}
                      value={valueRangeRuleWeek}
                      setStateValueAction={onChangeRuleWeekAction}
                      signatureValueEndLeft="%"
                      signatureValueEndRight="%"
                      signatureValueEndLeftBorder="% и менее"
                      signatureValueEndRightBorder="% и более"
                      signatureValueStartRightBorder="+"
                    />

                    <FilterRangeSlider
                      title="Месяц"
                      minValue={-30}
                      maxValue={30}
                      step={0.01}
                      value={valueRangeRuleMonth}
                      setStateValueAction={onChangeRuleMonthAction}
                      signatureValueEndLeft="%"
                      signatureValueEndRight="%"
                      signatureValueEndLeftBorder="% и менее"
                      signatureValueEndRightBorder="% и более"
                      signatureValueStartRightBorder="+"
                    />
                  </>
                }
              />

              <FilterGroupWrapper
                title="Во время прибыльности"
                formElements={
                  <>
                    <FilterRangeSlider
                      title="День"
                      minValue={-10}
                      maxValue={30}
                      step={0.01}
                      value={averageIncomeDuringProfitability}
                      setStateValueAction={setAverageIncomeDuringProfitabilityAction}
                      signatureValueEndLeft="%"
                      signatureValueEndRight="%"
                      signatureValueEndLeftBorder="% и менее"
                      signatureValueEndRightBorder="% и более"
                      signatureValueStartRightBorder="+"
                    />

                    <FilterRangeSlider
                      title="Неделя"
                      minValue={-10}
                      maxValue={30}
                      step={0.01}
                      value={averageIncomeDuringProfitabilityWeek}
                      setStateValueAction={setAverageIncomeDuringProfitabilityWeekAction}
                      signatureValueEndLeft="%"
                      signatureValueEndRight="%"
                      signatureValueEndLeftBorder="% и менее"
                      signatureValueEndRightBorder="% и более"
                      signatureValueStartRightBorder="+"
                    />

                    <FilterRangeSlider
                      title="Месяц"
                      minValue={-10}
                      maxValue={30}
                      step={0.01}
                      value={averageIncomeDuringProfitabilityMonth}
                      setStateValueAction={setAverageIncomeDuringProfitabilityMonthAction}
                      signatureValueEndLeft="%"
                      signatureValueEndRight="%"
                      signatureValueEndLeftBorder="% и менее"
                      signatureValueEndRightBorder="% и более"
                      signatureValueStartRightBorder="+"
                    />
                  </>
                }
              />

              <FilterGroupWrapper
                title="Время прибыльности"
                formElements={
                  <>
                    <FilterRangeSlider
                      title="День"
                      minValue={0}
                      maxValue={120}
                      value={valueRangeTimeProfit}
                      setStateValueAction={onChangeTimeProfitAction}
                      signatureValueEndLeft="м"
                      signatureValueEndRight="м"
                      signatureValueStartRightBorder=">"
                    />

                    <FilterRangeSlider
                      title="Неделя"
                      minValue={0}
                      maxValue={120}
                      value={valueRangeTimeProfitWeek}
                      setStateValueAction={onChangeTimeProfitWeekAction}
                      signatureValueEndLeft="м"
                      signatureValueEndRight="м"
                      signatureValueStartRightBorder=">"
                    />

                    <FilterRangeSlider
                      title="Месяц"
                      minValue={0}
                      maxValue={120}
                      value={valueRangeTimeProfitMonth}
                      setStateValueAction={onChangeTimeProfitMonthAction}
                      signatureValueEndLeft="м"
                      signatureValueEndRight="м"
                      signatureValueStartRightBorder=">"
                    />
                  </>
                }
              />

              <FilterGroupWrapper
                title="Времени прибыльности в %"
                formElements={
                  <>
                    <FilterRangeSlider
                      title="День"
                      minValue={0}
                      maxValue={100}
                      step={0.01}
                      value={valueRangeTimeProfitPercent}
                      setStateValueAction={onChangeTimeProfitPercentAction}
                      signatureValueEndLeft="%"
                      signatureValueEndRight="%"
                    />

                    <FilterRangeSlider
                      title="Неделя"
                      minValue={0}
                      maxValue={100}
                      step={0.01}
                      value={valueRangeTimeProfitPercentWeek}
                      setStateValueAction={onChangeTimeProfitPercentWeekAction}
                      signatureValueEndLeft="%"
                      signatureValueEndRight="%"
                    />

                    <FilterRangeSlider
                      title="Месяц"
                      minValue={0}
                      maxValue={100}
                      step={0.01}
                      value={valueRangeTimeProfitPercentMonth}
                      setStateValueAction={onChangeTimeProfitPercentMonthAction}
                      signatureValueEndLeft="%"
                      signatureValueEndRight="%"
                    />
                  </>
                }
              />

              <FilterCheckboxWrapper
                title="Статусы цепочек"
                formElements={
                  <>
                    <FilterCheckbox
                      label="Торговля возможна"
                      state={modeChainsStatus}
                      setStateStatusAction={setModeChainsStatusAction}
                      setStatus={[1, 2, 3]}
                      hint="Все транзакции по цепочке доступны, работа по цепочке возможна на текущий момент"
                    />

                    <FilterCheckbox
                      label="Торговля приостановлена"
                      state={modeChainsStatus}
                      setStateStatusAction={setModeChainsStatusAction}
                      setStatus={[5, 6, 7]}
                      hint="Один или несколько переводов не доступны. Например, площадка не работает или в стакане обмена нет предложений. Так же сюда попадают цепочки по которым на Платформе не достаточно данных в силу технических сбоев"
                    />

                    <FilterCheckbox
                      label="Удалены"
                      state={modeChainsStatus}
                      setStateStatusAction={setModeChainsStatusAction}
                      setStatus={[4, 8, 9]}
                      hint="Цепочки были удалены администраторами, т.к. не были прибыльными долгое время"
                    />
                  </>
                }
              />
            </Styled.AllFiltersBody>
          ) : null}

        <Styled.AllFiltersFooter>
          <Styled.AllFiltersReset onClick={onClickResetFilterHandler}>
            Сбросить фильтры
          </Styled.AllFiltersReset>

          <Styled.AllFiltersButtonWrapper
            onClick={onClickApplyFilterHandler}
          >
            <ImageContainer
              image={refresh} 
              width={17}
              height={17}
              fill={'#FFFFF'}
            />
            <Styled.AllFiltersButtonText>
              Применить фильтры
            </Styled.AllFiltersButtonText>
          </Styled.AllFiltersButtonWrapper>
        </Styled.AllFiltersFooter>
      </Styled.AllFiltersWrapper>
    </Styled.AllFilters>
  );
}
