import { PayloadAction } from '@reduxjs/toolkit';
import { IOnlineParsing } from '.';
import { IOnlineParsingMap, StateButtonParsing, TitleButtonParsing } from './types';

export const setProfitChain = (
  state: IOnlineParsing,
  { payload }: PayloadAction<string | null>,
) => {
  state.profit = payload;
};

export const setAmountChain = (
  state: IOnlineParsing,
  { payload }: PayloadAction<string | null>,
) => {
  state.amount = payload;
};

// Метод, который устанавливает начальную валюту в цепочке онлайн парсра
export const setStartCurrencyChain = (
  state: IOnlineParsing,
  {
    payload: {
      idChains,
      currency,
    },
  }: PayloadAction<{ idChains: number, currency: string }>,
) => {
  state.startCurrencyId = currency;
  const onlineParsingValueLoop = state.onlineParsing.get(idChains);

  if (onlineParsingValueLoop) {
    state.onlineParsing.set(idChains, {
      ...onlineParsingValueLoop,
      currencyStart: currency,
    });
  }
};

// Метод, которое включает или выключает отслеживание онлайн парсера в цепоче
export const startOrStopOnlineParsingInLoop = (
  state: IOnlineParsing,
  { payload }: PayloadAction<IOnlineParsingMap>,
) => {
  const { loopId = 0 } = payload;
  const onlineParsingValueLoop = state.onlineParsing.get(loopId);

  state.onlineParsing.set(loopId, {
    loopId,
    isActive: onlineParsingValueLoop ? !onlineParsingValueLoop.isActive : true,
    currencyStart: onlineParsingValueLoop ? onlineParsingValueLoop.currencyStart : payload.currencyStart,
    amount: onlineParsingValueLoop ? onlineParsingValueLoop.amount : payload.amount,
    profit: onlineParsingValueLoop ? onlineParsingValueLoop.profit : payload.profit,
    stateButton: onlineParsingValueLoop?.isActive ? StateButtonParsing.Stop : StateButtonParsing.Start,
    title: onlineParsingValueLoop?.isActive ? TitleButtonParsing.Stop : TitleButtonParsing.Start,
  });
};

export const setAmountOnlineParsing = (
  state: IOnlineParsing,
  {
    payload: {
      loopId,
      amount,
    },
  }: PayloadAction<{ loopId: number, amount: string | null }>,
) => {
  const onlineParsingValueLoop = state.onlineParsing.get(loopId);

  if (onlineParsingValueLoop) {
    state.onlineParsing.set(loopId, {
      ...onlineParsingValueLoop,
      profit: null,
      amount,
    });
  }
};

export const setProfitOnlineParsing = (
  state: IOnlineParsing,
  {
    payload: {
      loopId,
      profit,
    },
  }: PayloadAction<{ loopId: number, profit: string | null }>,
) => {
  const onlineParsingValueLoop = state.onlineParsing.get(loopId);

  if (onlineParsingValueLoop) {
    state.onlineParsing.set(loopId, {
      ...onlineParsingValueLoop,
      amount: null,
      profit,
    });
  }
};

export const setStateBtnStarted = (
  state: IOnlineParsing,
  { payload }: PayloadAction<number>,
) => {
  const onlineParsingValueLoop = state.onlineParsing.get(payload);

  if (onlineParsingValueLoop) {
    const isStateButtonInStart = onlineParsingValueLoop.stateButton === StateButtonParsing.Start;

    state.onlineParsing.set(payload, {
      ...onlineParsingValueLoop,
      stateButton: isStateButtonInStart ? StateButtonParsing.Stop : StateButtonParsing.Start,
      title: isStateButtonInStart ? TitleButtonParsing.Stop : TitleButtonParsing.Start,
    });
  }
};

export const setStatusOnlineParsing = (
  state: IOnlineParsing,
  {
    payload: {
      loopId,
      stateButton,
      title,
    },
  }: PayloadAction<{
    loopId: number,
    stateButton: number,
    title: string,
  }>,
) => {
  const onlineParsingValueLoop = state.onlineParsing.get(loopId);

  if (onlineParsingValueLoop) {
    state.onlineParsing.set(loopId, {
      ...onlineParsingValueLoop,
      stateButton: stateButton,
      title: title,
    });
  }
};

export const setNewPointInGraphSecond = (
  state: IOnlineParsing,
) => {
  const {
    onlineParsingDataChains,
  } = state;

  const newPointSpread = () => {
    const lastValue = onlineParsingDataChains[onlineParsingDataChains.length - 1]?.sumEnd || 1;
    const firstValue = onlineParsingDataChains[0]?.sumStart || 1;

    const newPointMarginal = ((lastValue - firstValue) / lastValue) * 100;

    state.spreadOnlineParsing.push(newPointMarginal);
  };

  const newPointTime = () => {
    const getZero = (num: number) => {
      if (num >= 0 && num < 10) {
        return `0${num}`;
      }
      return num;
    };

    const date = new Date();

    const month = getZero(date.getMonth() + 1);
    const day = getZero(date.getDate());
    const hour = getZero(date.getHours());
    const minutes = getZero(date.getMinutes());
    const seconds = getZero(date.getSeconds());

    const newPointTimeParsing = `${day}.${month}, ${hour}:${minutes}:${seconds}`;
    state.timeOnlineParsing.push(newPointTimeParsing);
  };

  newPointSpread();
  newPointTime();
};
