import React, { useEffect } from 'react';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { ErrorStatus } from './App.types';
import { UserPages } from './components/UserPages';
import {
  NON_LOGGED_PATHS, USER_STATUS,
} from './constants';
import { PATH } from '../constants/constants';
import { useAppDispatch, useAppSelector } from '../hooks/storeHooks';
import { logout } from '../redux/reducers/auth';
import { ModalMaintenancePeriod } from '../ui/ModalMaintenancePeriod';
import { Navmenu } from '../ui/Navmenu';

function App() {
  const {
    isLogged,
    userStatus = 0,
  } = useAppSelector((state) => state.auth);

  const {
    requestStatus,
  } = useAppSelector((state) => state.tabels);

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isUserStatus = USER_STATUS.includes(userStatus);
  const isNonLoggedPaths = NON_LOGGED_PATHS.includes(pathname);

  // Тут прописана логика навигации после авторизации пользователя
  useEffect(() => {
    if (!isLogged && !isNonLoggedPaths && !PATH.default) {
      navigate(PATH.signIn);
    } else if (isLogged && isNonLoggedPaths && isUserStatus) {
      navigate(PATH.chains);
    }
  }, [navigate, pathname, isLogged, userStatus]);

  // Тут прописываем навигуцию при возникновении ошибок
  useEffect(() => {
    if (requestStatus === ErrorStatus.invalidKey) {
      dispatch(logout());
      navigate(PATH.signIn);
    }
  }, [requestStatus]);

  return (
    <>
      {(isLogged && isUserStatus && pathname !== PATH.default) && (
        <>
          <Navmenu/>
          <ModalMaintenancePeriod/>
        </>
      )}
      <UserPages/>
    </>
  );
}

export default App;
