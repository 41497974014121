import React, { Suspense } from 'react';
import axios from 'axios';
import { enableMapSet } from 'immer';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import App from './App/App';
import { LanguageContainer } from './components/LanguageContainer';
import { _baseURL } from './default.evn';
import { GlobalStyle } from './GlobalStyle';
import { store, persistedStore } from './redux/store/store';
import { theme } from './utils/theme';

import './i18n/i18next';

axios.defaults.baseURL = _baseURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

enableMapSet();

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider {...{ theme }}>
      <Provider store={store}>
        <PersistGate persistor={persistedStore} loading={null}>
          <Router>
            <Suspense fallback={<LanguageContainer/>}>
              <HelmetProvider>
                <App/>
              </HelmetProvider>
            </Suspense>
            <GlobalStyle/>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
);
