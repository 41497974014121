import { createSlice } from '@reduxjs/toolkit';
import {
  setActivePath,
  setActiveId,
  setLoopID,
  setActiveElementAdminPanel,
  setActivePageScripts,
  setOpenMenu,
  setChoisePageDirectories,
  setOpenModalDeleteChains,
  setIsOpenModalFilters,
} from './action';

export interface NavState {
  activePath: string,
  activeId?: number,
  loopId?: string | null,
  activeElementAdminPanel: string;
  openModalDeleteChains: boolean;
  activePageScripts: number;
  openMenuItems: Map<string, boolean>;
  choisePageDirectories: Map<string, number>;
  isOpenModalFilters: boolean;
}

const initialState: NavState = {
  activePath: '/admin/login',
  loopId: '',
  activeElementAdminPanel: 'method',
  activePageScripts: 2,
  openModalDeleteChains: false,
  openMenuItems: new Map([
    ['directory', true],
    ['editing', false],
    ['scripts', false],
  ]),
  choisePageDirectories: new Map([
    ['method', 1],
    ['currencies', 1],
    ['platforms', 1],
    ['countries', 1],
  ]),
  isOpenModalFilters: false,
};

export const navSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setActivePath,
    setActiveId,
    setLoopID,
    setActiveElementAdminPanel,
    setActivePageScripts,
    setOpenMenu,
    setChoisePageDirectories,
    setOpenModalDeleteChains,
    setIsOpenModalFilters,
  },
});

export const {
  setActivePath: setActivePathAct,
  setActiveId: setActiveIdAct,
  setLoopID: setLoopIDAct,
  setActiveElementAdminPanel: setActiveElementAdminPanelAct,
  setActivePageScripts: setActivePageScriptsAct,
  setOpenMenu: setOpenMenuAction,
  setChoisePageDirectories: setChoisePageDirectoriesAct,
  setOpenModalDeleteChains: setOpenModalDeleteChainsAct,
  setIsOpenModalFilters: setIsOpenModalFiltersAction,
} = navSlice.actions;

export default navSlice.reducer;
