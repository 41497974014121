import { PayloadAction } from '@reduxjs/toolkit';
import { NavState } from '.';

export const setActivePath = (
  state: NavState,
  { payload }: PayloadAction<string>,
) => {
  state.activePath = payload;
};

export const setActiveId = (
  state: NavState,
  { payload } : PayloadAction<number | undefined>,
) => {
  state.activeId = payload;
};

export const setLoopID = (
  state: NavState,
  { payload } : PayloadAction<string | null>,
) => {
  state.loopId = payload;
};

export const setActiveElementAdminPanel = (
  state: NavState,
  { payload } : PayloadAction<string>,
) => {
  state.activeElementAdminPanel = payload;
};

export const setActivePageScripts = (
  state: NavState,
  { payload }: PayloadAction<number>,
) => {
  state.activePageScripts = payload;
};

export const setOpenMenu = (
  state: NavState,
  { payload }: PayloadAction<string>,
) => {
  state.openMenuItems.set(payload, !state.openMenuItems.get(payload));
};

export const setChoisePageDirectories = (
  state: NavState,
  { payload }: PayloadAction<number>,
) => {
  state.choisePageDirectories = new Map(
    state.choisePageDirectories.set(state.activeElementAdminPanel, payload),
  );
};

export const setOpenModalDeleteChains = (
  state: NavState,
) => {
  state.openModalDeleteChains = !state.openModalDeleteChains;
};

export const setIsOpenModalFilters = (
  state: NavState,
) => {
  state.isOpenModalFilters = !state.isOpenModalFilters;
};
